$cdn: 'https://cdn.millions.co';
@import "styles3/scss/_base";

.searchBarToggleButton {
  @extend %button-reset;
  padding: $spacing-4;
  height: 100%;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  background: $background-default;
  border: $border-1 solid $border-default;
  border-radius: $radius-1;
  box-sizing: border-box;
  overflow: hidden;

  .searchBarToggleButtonIcon {
    font-size: 24px;
    color: $lights-high;
  }
}

.searchBarToggleModal {
  &[class*="modal"] {
    margin-top: $header_height;

    @media screen and (min-width: $breakpoint_l) {
      margin-top: 0;
    }

    &[class*="size-medium"] {
      padding: 16px;
      max-width: 100vw;
      min-height: max-content;
      vertical-align: top;
      border-radius: 0;
    }
  }

  [class*="closeIcon"] {
    display: none;
  }
}
